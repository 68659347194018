import { LOCALE_ID } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IName } from '../../global.models'

export class Names {
  da: FormControl
  en: FormControl

  fb: FormBuilder
  language: string
  constructor(public names?: IName, length?: number, required?: boolean) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.language = FoodopLibModule.injector.get(LOCALE_ID)

    this.da = this.fb.control({ value: Array.isArray(names?.da) ? names?.da.join(',') : names?.da, disabled: false }, [this.language == 'da' && required != false ? Validators.required : Validators.nullValidator, Validators.maxLength(length || 50)])
    this.en = this.fb.control({ value: Array.isArray(names?.en) ? names?.en.join(', ') : names?.en, disabled: false }, [this.language == 'en' && required != false ? Validators.required : Validators.nullValidator, Validators.maxLength(length || 50)])
  }

  get valid(): boolean {
    return this.da.valid || this.en.valid
  }

  patchValue(names) {
    this.da.setValue(Array.isArray(names?.da) ? names?.da.join(',') : names?.da, { emitEvent: false })
    this.en.setValue(Array.isArray(names?.en) ? names?.en.join(', ') : names?.en, { emitEvent: false })
  }

  get as_dict(): IName {
    return {
      da: this.da.value,
      en: this.en.value
    }
  }
}
