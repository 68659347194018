import { Observable, forkJoin } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FoodopLibModule } from '../../foodop-lib.module'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { MenuDishesService } from '../menu-dish/menu-dishes.service'
import { Menu } from '../menu/menu.model'
import { MenusService } from '../menu/menus.service'
import { MenuSectionTemplate } from '../menu-templates/menu-section-template.model'
import { IMenuDish, IMenuSection, IName } from '../../global.models'
import moment from 'moment'
import { MenuSectionsService } from './menu-sections.service'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { TrackingsService } from '../tracking/trackings.service'
import { Recipe } from '../recipe/recipe.model'

export class MenuSection {
  id: string
  public menuSectionTemplateId: string
  menu_dishes: MenuDish[]
  section_index: number

  menu_id: string

  saved_menu_section: any

  menusService: MenusService
  menuSectionsService: MenuSectionsService
  menuDishesService: MenuDishesService
  trackingsService: TrackingsService
  subsidiaryService: SubsidiaryService
  func: GlobalFunctionsService

  constructor(public menu?: Menu, private menu_section?: IMenuSection) {
    this.menusService = FoodopLibModule.injector.get(MenusService)
    this.menuSectionsService = FoodopLibModule.injector.get(MenuSectionsService)
    this.menuDishesService = FoodopLibModule.injector.get(MenuDishesService)
    this.trackingsService = FoodopLibModule.injector.get(TrackingsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.id = menu_section?.id
    this.menuSectionTemplateId = menu_section?.menu_section_template_id
    this.menu_id = menu?.id
    this.menu_dishes = (menu_section?.menu_dishes || []).sort((a, b) => (a.id > b.id ? -1 : 1)).map((menu_dish) => new MenuDish(menu_dish, this)) as MenuDish[]
    this.section_index = menu_section?.section_index

    this.saved_menu_section = JSON.stringify(this.as_dict)
  }

  patchValues(menu_section: IMenuSection) {
    if (menu_section?.id) this.id = menu_section.id
    this.patchMenuDishes(menu_section.menu_dishes)
    this.saved_menu_section = JSON.stringify(this.as_dict)
  }

  patchMenuDishes(menu_dishes: IMenuDish[]) {
    if (menu_dishes) {
      this.menu_dishes = this.menu_dishes.filter((existing_menu_dish) => menu_dishes.find((menu_dish) => existing_menu_dish.recipe.id == menu_dish.recipe.id))
      menu_dishes.forEach((menu_dish) => {
        if (this.menu_dish_with_recipe_id(menu_dish.recipe.id)) this.menu_dish_with_recipe_id(menu_dish.recipe.id).patchValues(menu_dish)
        else {
          this.menu_dishes.push(new MenuDish(menu_dish, this))
        }
      })
    }
  }

  get section_template(): MenuSectionTemplate {
    const section = this.menu?.menuTemplate?.menuSectionTemplateWithId(this.menuSectionTemplateId)
    return section
  }
  get menu_dishes_sorted(): MenuDish[] {
    return this.menu_dishes.sort((a, b) => (a.index.value < b.index.value ? -1 : 1))
  }

  get is_new(): boolean {
    return this.id ? false : true
  }

  get changed(): boolean {
    return this.saved_menu_section != JSON.stringify(this.as_dict) || this.menu_dishes.some((menu_dish) => menu_dish.changed.value)
  }

  removeDuplicatedDishes(): MenuDish[] {
    let duplicated_dishes = this.menu_dishes.filter((menu_dish, index) => this.menu_dishes.findIndex((t) => t.recipe.id === menu_dish.recipe.id) !== index)
    this.menu_dishes = this.menu_dishes.filter((menu_dish, index, self) => self.findIndex((t) => t.recipe.id === menu_dish.recipe.id) === index)
    return duplicated_dishes
  }

  reindexDishes(): void {
    this.menu_dishes_sorted.forEach((menu_dish, index) => menu_dish.index.setValue(index))
  }

  menu_dish_with_recipe_id(recipe_id: string): MenuDish {
    return this.menu_dishes.find((menu_section_dish) => menu_section_dish.recipe.id == recipe_id)
  }

  moveSection(subsidiary_id: string, date: moment.Moment, menu_template_id: string, menuSectionTemplateId: string): Observable<any> {
    let to_menu: Menu = this._create_destination_menu(date, menu_template_id)
    let to_menu_section: MenuSection = this._create_destination_menu_section(to_menu, menuSectionTemplateId)
    let menu_dish_copies = this.menu_dishes.map((menu_dish, index) => {
      let menu_dish_copy: MenuDish = menu_dish.copy(false, to_menu_section)
      menu_dish_copy.index.setValue(null)
      return menu_dish_copy
    })
    if (this.menu_id != to_menu.id) this._clearScaleDishesForSection()
    this.menu_dishes = []
    return to_menu_section.addDishes(menu_dish_copies, subsidiary_id, this.menu_id, [this.id])
  }

  _clearScaleDishesForSection(): void {
    this.menu.trackings.forEach((tracking) => {
      this.menu_dishes.forEach((menu_dish) => {
        tracking.scaleDishesForMenuDish(menu_dish).forEach((scale_dish) => tracking.removeScaleDish(scale_dish))
      })
      if (tracking.scaleDishes.length) tracking.save().subscribe()
      else
        this.trackingsService.trackings.splice(
          this.trackingsService.trackings.findIndex((s) => s.id == tracking.id),
          1
        )
    })
  }
  copySection(subsidiary_id: string, dates: any, menu_template_id: string, menuSectionTemplateId: string): Observable<any> {
    const daterange: moment.Moment[] = this.func.createDaterange(dates, this.subsidiaryService.subsidiary?.show_weekends?.value)
    let completed_copies = []
    daterange.forEach((date) => {
      let to_menu: Menu = this._create_destination_menu(date, menu_template_id)
      let to_menu_section: MenuSection = this._create_destination_menu_section(to_menu, menuSectionTemplateId)
      let menu_dish_copies = this.menu_dishes.map((menu_dish, index) => {
        let menu_dish_copy: MenuDish = menu_dish.copy(true, to_menu_section)
        menu_dish_copy.index.setValue(null)
        return menu_dish_copy
      })
      completed_copies.push(to_menu_section.addDishes(menu_dish_copies, subsidiary_id))
    })
    return forkJoin(completed_copies)
  }

  _create_destination_menu(date: moment.Moment, menu_template_id: string): Menu {
    return this.menusService.menuForDateAndMenuTemplateId(date, menu_template_id) || new Menu({ menu_template_id: menu_template_id, date: date })
  }
  _create_destination_menu_section(to_menu: Menu, menuSectionTemplateId: string): MenuSection {
    return to_menu.section_for_section_template(menuSectionTemplateId)
  }
  _create_destination_menu_dishes(to_menu_section: MenuSection): MenuDish[] {
    return this.menu_dishes.map((menu_dish) => {
      let copy_of_menu_dish: MenuDish = new MenuDish(menu_dish.as_dict, to_menu_section)
      copy_of_menu_dish.id = null
      return copy_of_menu_dish
    })
  }

  public addRecipe(recipeNames: IName): void {
    const recipe: Recipe = new Recipe({ names: recipeNames })
    let menuDish: MenuDish = new MenuDish(null, this, recipe.as_dict, this.menu_dishes.length)
    menuDish.changed.setValue(true)
    menuDish.recipe.addingRecipeToMenu = true
    this.menu_dishes.push(menuDish)

    menuDish.recipe.save(true).subscribe((response) => {
      menuDish.recipe.patchValue(response['recipe_dict'])
      return this._upsertMenu([menuDish]).subscribe()
    })
  }

  public addDishes(menu_dishes: MenuDish[], subsidiary_id?: string, original_menu_id?: string, original_menu_section_ids?: string[]): Observable<any> {
    menu_dishes.forEach((menu_dish) => {
      menu_dish.recipe.addingRecipeToMenu = true
      menu_dish.changed.setValue(true)
      this.menu_dishes.push(menu_dish)
    })

    return this._upsertMenu(menu_dishes, subsidiary_id, original_menu_id, original_menu_section_ids)
  }

  private _upsertMenu(menu_dishes: MenuDish[], subsidiary_id?: string, original_menu_id?: string, original_menu_section_ids?: string[]): Observable<any> {
    return this.menusService.upsertMenu(this.menu, subsidiary_id, original_menu_id, original_menu_section_ids).pipe(
      tap((response) => {
        if (response) {
          menu_dishes.forEach((menu_dish) => {
            menu_dish.recipe.addingRecipeToMenu = false
          })
        }
      }),
      map(() => this.menu)
    )
  }

  removeDish(menu_dish: MenuDish) {
    menu_dish.recipe.removingRecipeFromMenu = true
    return this.menuDishesService.removeDishFromMenuSection(menu_dish.id, this.id, this.menu.id).pipe(
      tap((response) => {
        if (response) {
          menu_dish.recipe.removingRecipeFromMenu = false

          this._removeDishAndReindexSection(menu_dish)
          menu_dish.clearScaleDishesForDish()

          this.menu.id = response['menu_id']
          this.id = response['menu_section_id']
        }
      })
    )
  }

  _removeDishAndReindexSection(menu_dish: MenuDish): void {
    this.menu_dishes.splice(
      this.menu_dishes.findIndex((menu_section_dish) => menu_section_dish.id == menu_dish.id),
      1
    )
    if (this.menu_dishes.length) this.reindexDishes()
  }

  clearSection(): Observable<any> {
    return this.menuSectionsService.deleteMenuSection(this.id, this.menu_id).pipe(
      tap((response) => {
        this.id = null
        this.menu_dishes = []
        this.menu.id = response['menu_id']
        this._clearScaleDishesForSection()
      })
    )
  }

  dishesString(language: string) {
    if (this.menu_dishes.length) {
      let dishesString = ''
      this.menu_dishes.forEach((menu_dish: MenuDish, index: number) => {
        if (this.menu_dishes.length > 1 && index == this.menu_dishes.length - 1) dishesString += language == 'da' ? ' og ' : ' and '
        dishesString += menu_dish.recipe.names[language].value
        if (this.menu_dishes.length > 0 && index < this.menu_dishes.length - 2) dishesString += ', '
      })
      return dishesString
    } else {
      return language == 'da' ? 'Ingen retter tilføjet' : 'No dishes selected'
    }
  }

  get as_dict(): IMenuSection {
    return {
      id: this.id,
      menu_section_template_id: this.menuSectionTemplateId,
      section_index: this.section_index,
      section_template: this.section_template?.asDict,
      menu_id: this.menu?.id
    }
  }

  get upload_dict(): IMenuSection {
    return {
      id: this.id,
      menu_section_template_id: this.menuSectionTemplateId,
      section_index: this.section_index,
      menu_dishes: this.menu_dishes_sorted?.filter((menu_dish) => !menu_dish.id || menu_dish.changed.value).map((menu_dish) => menu_dish.as_dict),
      section_template: this.section_template?.asDict,
      menu_id: this.menu?.id
    }
  }
}
