import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FoodopLibModule } from '../../foodop-lib.module'
import { BowlSetup } from '..//bowls/bowl-setup/bowl-setup.model'
import { Scale } from '..//scale/scale.model'
import { ScaleGroup } from '../scale-group/scale-group.model'
import { LocationsService } from './locations.service'
import { ILocation } from '../../global.models'
import { EScaleGroupTypes } from '../../global.types'
import { Gateway } from '../gateways/gateway.model'
import { GatewaysService } from '../gateways/gateways.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { Subsidiary } from '../subsidiary/subsidiary.model'
import { ScalesService } from '../scale/scales.service'
import { TrackingTemplatesService } from '../tracking-templates/tracking-templates.service'
import { TrackingTemplate } from '../tracking-templates/tracking-template.model'

export class Location {
  id: string

  name: FormControl
  index: FormControl
  active: FormControl

  address: FormControl
  postal: FormControl
  city: FormControl
  country: FormControl
  language: FormControl

  subsidiary_id: FormControl

  menu_templates: FormControl

  scale_groups: ScaleGroup[]

  saved_location: any
  saving = false

  bowl_setups: BowlSetup[]

  default_display_template: FormControl
  servesFood: FormControl

  is_loading = false

  subsidiaryService: SubsidiaryService
  locationsService: LocationsService
  gatewaysService: GatewaysService
  scalesService: ScalesService
  private _trackingTemplatesService: TrackingTemplatesService

  fb: FormBuilder

  constructor(public location?: ILocation) {
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.locationsService = FoodopLibModule.injector.get(LocationsService)
    this.gatewaysService = FoodopLibModule.injector.get(GatewaysService)
    this.scalesService = FoodopLibModule.injector.get(ScalesService)
    this._trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this._storeRelatedObjects(location)

    this.id = location?.id
    this.active = this.fb.control({ value: location?.active, disabled: false }, [Validators.required])
    this.name = this.fb.control({ value: location?.name, disabled: false }, [Validators.required])
    this.language = this.fb.control({ value: location?.language || 'da', disabled: false }, [Validators.required])
    this.subsidiary_id = this.fb.control({ value: location?.subsidiary_id, disabled: false }, [Validators.required])
    this.index = this.fb.control({ value: location?.index || this.subsidiary?.locations?.length, disabled: false }, [Validators.required])

    this.address = this.fb.control(location?.address)
    this.postal = this.fb.control(location?.postal)
    this.city = this.fb.control(location?.city)
    this.country = this.fb.control(location?.country)

    this.scale_groups = (location?.scale_groups || []).map((scale_group) => new ScaleGroup(Object.assign(scale_group, { location_id: location?.id })))
    this.bowl_setups = (location?.bowl_setups || []).map((bowl_setup) => new BowlSetup(bowl_setup))
    this.servesFood = this.fb.control(location?.serves_food)

    this.saved_location = JSON.stringify(this.as_dict)
  }

  patchValues(location: ILocation): void {
    this._storeRelatedObjects(location)

    if (location?.id) this.id = location.id
    if (location?.index != undefined) this.index.setValue(location.index)
    if (location?.active != undefined) this.active.setValue(location.active)
    if (location?.name) this.name.setValue(location.name)
    if (location?.subsidiary_id) this.subsidiary_id.setValue(location.subsidiary_id)
    if (location?.scale_groups) this.scale_groups = (location?.scale_groups || []).map((scale_group) => new ScaleGroup(Object.assign(scale_group, { location_id: location?.id })))
    if (location?.language) this.language.setValue(location.language)
    if (location?.serves_food) this.servesFood.setValue(location.serves_food)

    this.saved_location = JSON.stringify(this.as_dict)
  }

  loadWasteTrackingRulesForLocation(): Observable<ILocation> {
    this.is_loading = true
    return this.locationsService.getLocation(this.id).pipe(
      tap((location: ILocation) => {
        if (location) {
          this.patchValues(location)
        }
        this.is_loading = false
      })
    )
  }

  get subsidiary(): Subsidiary {
    return this.subsidiaryService.subsidiary_with_id(this.subsidiary_id.value)
  }

  get wasteTrackings(): TrackingTemplate[] {
    return this._trackingTemplatesService.trackingTemplatesForLocation(this.id, 'waste')
  }

  get scales(): Scale[] {
    const scales = []
    this.scale_groups.forEach((scale_group) => {
      scale_group.scales.forEach((scale) => {
        scales.push(scale)
      })
    })
    return scales
  }

  public get type(): string {
    return this.waste_scale_groups.length ? 'waste' : 'menu'
  }

  get waste_scale_groups(): ScaleGroup[] {
    return this.scale_groups.filter((scale_group) => scale_group.type.value == 'trash' && scale_group.scales.length)
  }

  get waste_scales(): Scale[] {
    const waste_scales = []
    this.waste_scale_groups.forEach((waste_scale_group) => {
      waste_scale_group.scales.forEach((waste_scale) => {
        waste_scales.push(waste_scale)
      })
    })
    return waste_scales
  }

  get dish_scale_groups(): ScaleGroup[] {
    return this.scale_groups.filter((scale_group) => scale_group.type.value == 'dish')
  }
  get dish_scales(): Scale[] {
    const dish_scales = []
    this.dish_scale_groups.forEach((dish_scale_group) => {
      dish_scale_group.scales.forEach((dish_scale) => {
        dish_scales.push(dish_scale)
      })
    })
    return dish_scales
  }

  get display_scale_groups(): ScaleGroup[] {
    return this.scale_groups.filter((scale_group) => scale_group.type.value == 'display')
  }
  get display_scales(): Scale[] {
    const display_scales = []
    this.display_scale_groups.forEach((display_scale_group) => {
      display_scale_group.scales.forEach((display_scale) => {
        display_scales.push(display_scale)
      })
    })
    return display_scales
  }

  get dish_and_display_scale_groups(): ScaleGroup[] {
    return this.scale_groups.filter((scale_group) => scale_group.type.value == 'dish' || scale_group.type.value == 'display')
  }
  get dish_and_display_scales(): Scale[] {
    const dish_and_display_scales = []
    this.dish_and_display_scale_groups.forEach((dish_and_display_scale_group) => {
      dish_and_display_scale_group.scales.forEach((dish_and_display_scale) => {
        dish_and_display_scales.push(dish_and_display_scale)
      })
    })
    return dish_and_display_scales
  }

  scale_with_macc(scale_macc: string): Scale {
    return this.scales.find((scale) => scale.macc == scale_macc)
  }

  scale_group_with_id(scale_group_id: string): ScaleGroup {
    return this.scale_groups.find((scale_group) => scale_group.id == scale_group_id)
  }

  get trash_scale_groups(): ScaleGroup[] {
    return this.scale_groups.filter((scale_group) => scale_group.type.value == EScaleGroupTypes.trash)
  }

  get scale_groups_sorted(): ScaleGroup[] {
    return this.scale_groups.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  get gateways(): Gateway[] {
    return this.gatewaysService.gateways.filter((gateway) => gateway.location_id.value == this.id)
  }

  get changed(): boolean {
    return this.is_new || this.saved_location != JSON.stringify(this.as_dict)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.scale_groups.find((scale_group) => scale_group.valid == false) == undefined
  }

  get is_new(): boolean {
    return !this.id
  }

  save(): Observable<Location> {
    this.saving = true
    return this.locationsService.saveLocation(this).pipe(
      tap((location) => {
        this.patchValues(location)
        this.saved_location = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  get as_dict(): ILocation {
    return {
      id: this.id,
      index: this.index.value,
      active: this.active.value,
      name: this.name.value,
      address: this.address.value,
      postal: this.postal.value,
      city: this.city.value,
      country: this.country.value,
      language: this.language.value,
      scale_groups: this.scale_groups_sorted.map((scale_group) => scale_group.as_dict),
      subsidiary_id: this.subsidiary_id.value,
      serves_food: this.servesFood.value
    }
  }

  private _storeRelatedObjects(location): void {
    location['gateways']?.forEach((gateway) => this.gatewaysService.addToGateways(Object.assign(gateway, { location_id: location?.id })))
    location['scale_groups']?.forEach((scale_group) => scale_group['scales']?.forEach((scale) => this.scalesService.addToScales(Object.assign(scale, { location_id: location?.id }))))
  }
}
