import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { combineLatest, Observable, tap } from 'rxjs'
import { UserService } from '../models/user/user.service'
import { OrganizationsService } from '../models/organization/organizations.service'
import { SubsidiaryService } from '../models/subsidiary/subsidiary.service'
import { LoadingService } from './loading.service'

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {
  constructor(private _loadingService: LoadingService, private _userService: UserService, private _subsidiaryService: SubsidiaryService, private _organizationsService: OrganizationsService) {}

  resolve(): Observable<any> {
    if (this._userService.user) {
      return this._loadData()
    } else {
      this._loadHubspotScript()
    }
  }

  private _loadData(): Observable<any> {
    this._loadingService.setLoading(true)
    return combineLatest(
      this._subsidiaryService.getSubsidiaryForUser().pipe(tap(() => this._loadingService.setLoadValue(this._loadingService.loadValue + 33))),
      this._organizationsService.loadOrganization().pipe(tap(() => this._loadingService.setLoadValue(this._loadingService.loadValue + 33)))
    ).pipe(
      tap(() => {
        this._loadHubspotScript()
        this._loadingService.setLoading(false)
      })
    )
  }

  private _loadHubspotScript() {
    let node = document.createElement('script') // creates the script tag
    node.appendChild(document.createTextNode('var _hsq = window._hsq = window._hsq || [];'))
    node.appendChild(document.createTextNode('_hsq.push(["identify",{'))
    node.appendChild(document.createTextNode("email: '" + this._userService.user?.email?.value + "'"))
    node.appendChild(document.createTextNode(`}]);`))

    node.appendChild(document.createTextNode("_hsq.push(['trackPageView']);"))
    node.appendChild(document.createTextNode('setTimeout( () => {'))
    node.appendChild(document.createTextNode('  window.HubSpotConversations?.widget.refresh();'))
    node.appendChild(document.createTextNode('}, 1000)'))
    document.getElementsByTagName('head')[0].appendChild(node)

    let node2 = document.createElement('script')
    node2.src = '//js-eu1.hs-scripts.com/25764977.js'
    node2.type = 'text/javascript'
    node2.async = true
    node2.id = 'hs-script-loader'
    document.getElementsByTagName('head')[0].appendChild(node2)
  }
}
