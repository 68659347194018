import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { AuthService } from './auth.service'
import { filter, map, Observable, of, switchMap, tap } from 'rxjs'
import { UserService } from '../models/user/user.service'
import { LoadingService } from './loading.service'
import { User } from '../models/user/user.model'
import { InteractionStatus } from '@azure/msal-browser'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private router: Router, private _authService: AuthService, private _loadingService: LoadingService, private _userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.data?.allowedDomains?.length || route.data?.loadUser) this._loadingService.setLoading(true)
    return this._checkAuthentication(route)
  }

  private _checkAuthentication(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => {
          return status === InteractionStatus.None
        })
      )
      .pipe(
        switchMap(() => {
          if ((route.data?.allowedDomains?.length || route.data?.loadUser) && !this.msalService.instance.getActiveAccount()) {
            this._authService.login()
            return of(false)
          } else if (this.msalService.instance.getActiveAccount())
            return this._loadUser().pipe(
              map(() => {
                if (this._checkAccess(this._userService.user, route.data?.allowedDomains || [])) {
                  return true
                } else if (!this._checkAccess(this._userService.user, ['menu_planning', '']) && this._checkAccess(this._userService.user, ['reporting_view'])) {
                  this.router.navigate(['/reportingOverview'])
                  return false
                } else return false
              })
            )
          else return of(true)
        })
      )
  }

  private _checkAccess(user: User, allowedDomains: string[]): boolean {
    return !allowedDomains.length || (user && user.domains.value.find((domain) => allowedDomains.includes(domain.name)) != undefined)
  }

  private _loadUser(): Observable<any> {
    return this._userService.loadUser().pipe(
      tap(() => {
        this._loadingService.setLoadValue(this._loadingService.loadValue + 33)
      })
    )
  }
}
