import { Injectable } from '@angular/core'
import { Recipe } from './recipe.model'
import { RecipePortion } from './recipe-portion.model'
import { merge, Observable, Subscription, tap } from 'rxjs'
import { RecipePortionIngredient } from '../raw-ingredient/recipe-portion-ingredient.model'
import { RecipePortionSubRecipe } from './recipe-portion-sub-recipe.model'

@Injectable({
  providedIn: null
})
export class RecipesScalingService {
  private _subscriptions: Subscription[] = []

  public listenForPortionScaling(recipe: Recipe): void {
    if (this._subscriptions.length) this._subscriptions.forEach((subscription) => subscription.unsubscribe())
    this._subscriptions = [
      this._listenForPortionServingsChanges(recipe).subscribe(),
      this._listenForPortionSizeChanges(recipe).subscribe(),
      this._listenForTotalIngredientAmountChanges(recipe).subscribe(),
      this._listenForTotalSubRecipeAmountChanges(recipe).subscribe(),
      this._listenForIngredientBruttoAmountChanges(recipe).subscribe(),
      this._listenForSubRecipeBruttoAmountChanges(recipe).subscribe(),
      this._listenForIngredientUnitChanges(recipe).subscribe(),
      this._listenForSubRecipeUnitChanges(recipe).subscribe(),
      this._listenForIngredientShrinkageAndPreparationFactorChanges(recipe).subscribe(),
      this._listenForIngredientNettoFactorChanges(recipe).subscribe(),
      this._listenForIngredientCalculatedShareChanges(recipe).subscribe()
    ]
  }

  public updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe: Recipe): void {
    recipe.total_ingredient_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  public updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe: Recipe): void {
    recipe.total_sub_recipe_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => portion.portion_sub_recipes_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  public updatePortionSizesBasedOnNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe.func.roundString(recipe_portion.servings.value) == 0) recipe_portion.servings.setValue('100', { emitEvent: false })
      recipe_portion.portion_size_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.servings_long
      recipe_portion.portion_size.setValue(recipe.func.round(recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
    })
  }

  public updatePortionServingsBasedOnNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe.func.roundString(recipe_portion.portion_size.value) == 0) recipe_portion.portion_size.setValue('100', { emitEvent: false })
      recipe_portion.servings_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.portion_size_long
      recipe_portion.servings.setValue(recipe.func.round(recipe_portion.servings_long, 0).toString(), { emitEvent: false })
    })
  }

  public updateTotalIngredientBruttoAmount(recipe: Recipe): void {
    if (recipe.are_total_portion_amounts_valid) {
      if (recipe.sum_of_portion_unlocked_sub_ingredient_shares == 0) this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
      else {
        this.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe)
        this.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe)
      }
      recipe.custom_portions = true
    }
  }

  // Listeners
  private _listenForPortionSizeChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.portions.map((portion) => portion.portionSizeChange$)).pipe(
      tap(() => {
        this._updatePortionsBasedOnPortionSizes(recipe)
        this.updateTotalIngredientBruttoAmount(recipe)
      })
    )
  }

  private _listenForPortionServingsChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.portions.map((portion) => portion.servingsChange$)).pipe(
      tap(() => {
        this._updatePortionsBasedOnServings(recipe)
        this.updateTotalIngredientBruttoAmount(recipe)
      })
    )
  }

  private _listenForIngredientBruttoAmountChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.portions.map((portion) => portion.ingredientAmountChange$)).pipe(
      tap(() => {
        this.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe)
        this._updatePortionServingSizes(recipe)
      })
    )
  }

  private _listenForSubRecipeBruttoAmountChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.portions.map((portion) => portion.subRecipeAmountChange$)).pipe(
      tap(() => {
        this.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe)
        this._updatePortionServingSizes(recipe)
        recipe.portions.forEach((recipe_portion) => {
          this._updateIngredientBruttoAmounts(recipe_portion)
        })
      })
    )
  }

  // FIX: When deleting the total ingredient amount, portion shares are reset to be equal
  private _listenForTotalIngredientAmountChanges(recipe: Recipe): Observable<any> {
    return recipe.total_ingredient_brutto_amount_in_kg.valueChanges.pipe(
      tap(() => {
        this._updatePortionIngredientBruttoAmountsBasedOnCalculatedShares(recipe)
        if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
        else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)
      })
    )
  }

  private _listenForTotalSubRecipeAmountChanges(recipe: Recipe): Observable<any> {
    return recipe.total_sub_recipe_brutto_amount_in_kg.valueChanges.pipe(
      tap(() => {
        this._updateSubRecipePortionAmountsBasedOnCalculatedShares(recipe)
        if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
        else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)
        recipe.portions.forEach((recipe_portion) => {
          this._updateIngredientBruttoAmounts(recipe_portion)
        })
      })
    )
  }

  private _listenForSubRecipeUnitChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.sub_recipes.map((sub_recipe) => sub_recipe.unit.valueChanges)).pipe(
      tap(() => {
        this._updatePortionServingSizes(recipe)
        this._updateTotalSubRecipeBruttoAmountBasedOnServingSizes(recipe)
        this._updateSubIngredientCalculatedShares(recipe)
        recipe.portions.forEach((recipe_portion) => {
          this._updateIngredientBruttoAmounts(recipe_portion)
        })
        recipe.updateRecipeCalculations()
      })
    )
  }

  private _listenForIngredientUnitChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.ingredients.map((ingredient) => ingredient.unit.valueChanges)).pipe(
      tap(() => {
        this._updatePortionServingSizes(recipe)
        this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
        this._updateSubIngredientCalculatedShares(recipe)
        recipe.updateRecipeCalculations()
      })
    )
  }

  private _listenForIngredientShrinkageAndPreparationFactorChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.shrinkageAndPreparationFactorChange$)).pipe(
      tap(() => {
        if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
        else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)

        recipe.nutrition = recipe.calculateNutrition()
      })
    )
  }

  private _listenForIngredientNettoFactorChanges(recipe: Recipe): Observable<any> {
    return merge(...recipe.ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.nettoFactorChange$)).pipe(
      tap(() => {
        if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
        else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)

        recipe.nutrition = recipe.calculateNutrition()
      })
    )
  }

  private _listenForIngredientCalculatedShareChanges(recipe: Recipe): Observable<any> {
    return merge(...[].concat.apply(recipe.portions.map((portion) => portion.portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_share.valueChanges))).flat()).pipe(
      tap(() => {
        recipe.updateRecipeCalculations()
      })
    )
  }

  // Updaters
  private _updatePortionServingSizes(recipe: Recipe): void {
    if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnIngredientNettoAmounts(recipe)
    else if (!recipe.servings_locked) {
      this._updatePortionServingsBasedOnIngredientNettoAmounts(recipe)
      recipe.custom_portions = true
    }
  }
  private _updatePortionsBasedOnPortionSizes(recipe: Recipe): void {
    if (!recipe.servings_locked) {
      this.updatePortionServingsBasedOnNettoAmounts(recipe)
      recipe.custom_portions = true
    } else this._updatePortionIngredientBruttoAmounts(recipe)
  }

  private _updatePortionsBasedOnServings(recipe: Recipe): void {
    if (!recipe.portion_size_locked) this.updatePortionSizesBasedOnNettoAmounts(recipe)
    else this._updatePortionIngredientBruttoAmounts(recipe)
  }

  private _updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe: Recipe): void {
    recipe.total_ingredient_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => (portion.servings_long * portion.portion_size_long) / 1000 - portion.portion_sub_recipes_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  private _updateTotalSubRecipeBruttoAmountBasedOnServingSizes(recipe: Recipe): void {
    recipe.total_sub_recipe_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => (portion.servings_long * portion.portion_size_long) / 1000 - portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  private _updatePortionIngredientBruttoAmountsBasedOnCalculatedShares(recipe: Recipe): void {
    const old_brutto_amount = recipe.portions.map((portion) => portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0)
    const sum_of_portion_netto_amounts_based_on_serving_sizes: number = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    let portion_shares_of_total_amount = recipe.portions.map((portion) => (old_brutto_amount ? portion.portion_ingredients_brutto_amount / old_brutto_amount : portion.portion_share(sum_of_portion_netto_amounts_based_on_serving_sizes)))

    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      recipe_portion.unlocked_portion_ingredients.forEach((ingredient) => {
        ingredient.setBruttoAmountBasedOnCalculatedShares((parseFloat(recipe.total_ingredient_brutto_amount_in_kg.value) || 0) * 1000 * portion_shares_of_total_amount[recipe_portion_index], recipe_portion.sum_of_unlocked_ingredient_shares)
      })
    })
  }

  private _updateSubIngredientCalculatedShares(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      recipe_portion.portion_ingredients_and_sub_recipes.forEach((sub_ingredient) => {
        sub_ingredient.updateIngredientCalculatedShares(recipe_portion.portion_brutto_amount)
      })
    })
  }

  private _updatePortionSizesBasedOnIngredientNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe_portion.func.roundString(recipe_portion.servings.value) == 0) recipe_portion.servings.setValue('100', { emitEvent: false })
      recipe_portion.portion_size_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.servings_long
      recipe_portion.portion_size.setValue(recipe.func.round(recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
    })
  }

  private _updatePortionServingsBasedOnIngredientNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe_portion.func.roundString(recipe_portion.portion_size.value) == 0) recipe_portion.portion_size.setValue('100', { emitEvent: false })
      recipe_portion.servings_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.portion_size_long
      recipe_portion.servings.setValue(recipe_portion.func.round(recipe_portion.servings_long, 0).toString(), { emitEvent: false })
    })
  }

  private _updatePortionSizesBasedOnServingSizes(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const total_netto_amount = recipe.sum_of_portions_netto_amounts_in_g
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      const portion_share_of_total_amount = sum_of_portion_netto_amounts_based_on_serving_sizes ? recipe_portion.portion_netto_amount_based_on_serving_sizes / sum_of_portion_netto_amounts_based_on_serving_sizes : this._portion_amount_share(recipe, recipe_portion_index)
      const portion_netto_amount = total_netto_amount * portion_share_of_total_amount
      recipe_portion.portion_size_long = portion_netto_amount / recipe_portion.servings_long
      recipe_portion.portion_size.setValue(recipe.func.round(recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
    })
  }
  private _updatePortionServingsBasedOnServingSizes(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const total_netto_amount = recipe.sum_of_portions_netto_amounts_in_g
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      const portion_share_of_total_amount = sum_of_portion_netto_amounts_based_on_serving_sizes ? recipe_portion.portion_netto_amount_based_on_serving_sizes / sum_of_portion_netto_amounts_based_on_serving_sizes : this._portion_amount_share(recipe, recipe_portion_index)
      const portion_netto_amount = total_netto_amount * portion_share_of_total_amount
      recipe_portion.servings_long = portion_netto_amount / recipe_portion.portion_size_long
      recipe_portion.servings.setValue(recipe.func.round(recipe_portion.servings_long, 0).toString(), { emitEvent: false })
    })
  }

  private _updateSubRecipePortionAmountsBasedOnCalculatedShares(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const old_brutto_amount = recipe.portions.map((portion) => portion.portion_brutto_amount).reduce((a, b) => a + b, 0)
    const portion_shares_of_total_amount = recipe.portions.map((recipe_portion) => (old_brutto_amount ? recipe_portion.portion_brutto_amount / old_brutto_amount : recipe_portion.portion_share(sum_of_portion_netto_amounts_based_on_serving_sizes)))
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      recipe_portion.unlocked_portion_sub_recipes.forEach((sub_recipe) => {
        sub_recipe.setBruttoAmountBasedOnCalculatedShares((parseFloat(recipe.total_sub_recipe_brutto_amount_in_kg.value) || 0) * 1000 * portion_shares_of_total_amount[recipe_portion_index], recipe_portion.sum_of_unlocked_sub_recipe_shares)
      })
    })
  }

  private _updatePortionIngredientBruttoAmounts(recipe: Recipe): void {
    recipe.portions
      .filter((portion) => recipe.func.roundString(portion.portion_size.value) > 0 && recipe.func.roundString(portion.servings.value) > 0)
      .forEach((portion) => {
        if (this._can_any_portion_ingredients_be_updated(recipe, portion)) this._updateIngredientBruttoAmounts(portion)
        else if (portion.is_total_amount_valid) {
          if (portion.sum_of_unlocked_sub_ingredient_shares == 0 && recipe.default_portion.sum_of_unlocked_sub_ingredient_shares > 0) {
            this._patchCalculatedSharesFromDefaultPortion(portion, recipe.default_portion.portion_ingredients, recipe.default_portion.portion_sub_recipes)
            this._updateIngredientBruttoAmounts(portion)
          } else {
            this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
          }
        } else if (!portion.is_total_amount_valid) {
          console.log('Invalid input')
        }
      })
  }

  private _patchCalculatedSharesFromDefaultPortion(recipe_portion: RecipePortion, default_portion_ingredients: RecipePortionIngredient[], default_portion_sub_recipes: RecipePortionSubRecipe[]): void {
    recipe_portion.portion_ingredients.forEach((portion_ingredient) => {
      const match = default_portion_ingredients.find((default_portion_ingredient) => default_portion_ingredient.object.id == portion_ingredient.object.id)
      if (match) portion_ingredient.calculated_share.setValue(match.calculated_share.value, { emitEvent: false })
    })
    recipe_portion.portion_sub_recipes.forEach((portion_sub_recipe) => {
      const match = default_portion_sub_recipes.find((default_portion_sub_recipe) => default_portion_sub_recipe.object.id == portion_sub_recipe.object.id)
      if (match) portion_sub_recipe.calculated_share.setValue(match.calculated_share.value, { emitEvent: false })
    })
  }

  private _updateIngredientBruttoAmounts(recipe_portion: RecipePortion): void {
    const total_portion_netto_amount = (recipe_portion.servings_long * recipe_portion.portion_size_long) / 1000
    const diff_netto_amount_to_distribute: number = total_portion_netto_amount - recipe_portion.sum_of_sub_ingredient_netto_amounts
    const sum_of_sub_ingredient_netto_amounts = recipe_portion.sum_of_sub_ingredient_netto_amounts
    const sum_of_unlocked_sub_ingredient_netto_shares: number = recipe_portion.unlocked_portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_netto_share(sum_of_sub_ingredient_netto_amounts)).reduce((a, b) => a + b, 0)
    const sum_of_unlocked_sub_ingredient_calculated_shares: number = recipe_portion.unlocked_portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_share.value).reduce((a, b) => a + b, 0)
    if (sum_of_unlocked_sub_ingredient_netto_shares) {
      recipe_portion.unlocked_portion_ingredients
        .filter((portion_ingredient) => portion_ingredient.calculated_share.value != undefined)
        .forEach((portion_ingredient) => {
          portion_ingredient.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (portion_ingredient.calculated_netto_share(sum_of_sub_ingredient_netto_amounts) / sum_of_unlocked_sub_ingredient_netto_shares))
        })
      recipe_portion.unlocked_portion_sub_recipes.forEach((sub_recipe) => {
        if (sub_recipe.calculated_share.value != undefined) sub_recipe.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (sub_recipe.calculated_netto_share(sum_of_sub_ingredient_netto_amounts) / sum_of_unlocked_sub_ingredient_netto_shares))
        this._scaleSubRecipeIngredients(sub_recipe)
      })
    } else if (sum_of_unlocked_sub_ingredient_calculated_shares) {
      recipe_portion.unlocked_portion_ingredients
        .filter((portion_ingredient) => portion_ingredient.calculated_share.value != undefined)
        .forEach((portion_ingredient) => {
          portion_ingredient.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (portion_ingredient.calculated_share.value / sum_of_unlocked_sub_ingredient_calculated_shares))
        })
      recipe_portion.unlocked_portion_sub_recipes.forEach((sub_recipe) => {
        if (sub_recipe.calculated_share.value != undefined) sub_recipe.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (sub_recipe.calculated_share.value / sum_of_unlocked_sub_ingredient_calculated_shares))
        this._scaleSubRecipeIngredients(sub_recipe)
      })
    }
    if (recipe_portion.portion_netto_amount_based_on_serving_sizes) this._updateIngredientCalculatedShares(recipe_portion)
  }

  private _scaleSubRecipeIngredients(sub_recipe: RecipePortionSubRecipe): void {
    sub_recipe.object.default_portion.servings_long = sub_recipe.amount_in_kg
    sub_recipe.object.default_portion.portion_size_long = 1000

    sub_recipe.object.default_portion.portion_ingredients.forEach((portion_ingredient: RecipePortionIngredient) => {
      portion_ingredient.setBruttoAmountBasedOnCalculatedShares(
        sub_recipe.object.default_portion.portion_netto_amount_based_on_serving_sizes * (sub_recipe.object.default_portion.sum_of_ingredient_shares / 100),
        sub_recipe.object.default_portion.sum_of_ingredient_shares
      )
    })
    sub_recipe.object.default_portion.portion_sub_recipes.forEach((portionSubRecipe: RecipePortionSubRecipe) => {
      portionSubRecipe.setBruttoAmountBasedOnCalculatedShares(
        (sub_recipe.object.default_portion.portion_netto_amount_based_on_serving_sizes * (100 - sub_recipe.object.default_portion.sum_of_ingredient_shares)) / 100,
        100 - sub_recipe.object.default_portion.sum_of_ingredient_shares
      )
    })
  }

  private _updateIngredientCalculatedShares(recipe_portion: RecipePortion): void {
    recipe_portion.portion_ingredients_and_sub_recipes.forEach((sub_ingredient) => {
      sub_ingredient.updateIngredientCalculatedShares(recipe_portion.portion_brutto_amount)
    })
  }

  private _can_any_portion_ingredients_be_updated(recipe: Recipe, portion: RecipePortion): boolean {
    return !recipe.amount_locked_for_all_sub_ingredients && portion.sum_of_unlocked_sub_ingredient_shares > 0 && portion.is_total_amount_valid
  }

  private _portion_amount_share(recipe: Recipe, index: number): number {
    if (recipe.subsidiary_has_portion_templates && index < recipe.subsidiaryService.subsidiary?.portion_templates?.length)
      return recipe.subsidiaryService.subsidiary.portion_templates[index].servings.value / recipe.subsidiaryService?.subsidiary.portion_templates.map((portion_template) => portion_template.servings.value).reduce((a, b) => a + b, 0)
    else return 0
  }
}
