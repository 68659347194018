import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Subsidiary } from './subsidiary.model'
import { ISubsidiary } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'
import { AdminApiService } from '../../services/admin-api.service'
import { LocationsService } from '../location/locations.service'

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryService {
  subsidiary: Subsidiary
  subsidiaries: Subsidiary[] = []

  constructor(public fb: FormBuilder, private restApiService: RestApiService, private adminApiService: AdminApiService, private route: ActivatedRoute, private router: Router, @Inject(LOCALE_ID) public language: string, private locationsService: LocationsService) {}

  getSubsidiaryForUser(): Observable<ISubsidiary> {
    const params = {
      fields: 'locations,scale_groups,scales,portion_templates,serving_templates',
      language: this.language
    }
    return this.restApiService.getSubsidiary(params).pipe(
      tap((subsidiary) => {
        subsidiary?.locations?.forEach((location) => {
          this.locationsService.addToLocations(Object.assign(location, { subsidiary_id: subsidiary.id }))
        })
        this.addToSubsidiaries(subsidiary)
        this.subsidiary = this.subsidiary_with_id(subsidiary.id)
        this.subsidiary.has_loaded_details = true
      })
    )
  }

  addToSubsidiaries(subsidiary: ISubsidiary): void {
    const exisiting_subsidiary: Subsidiary = this.subsidiary_with_id(subsidiary.id)
    if (!exisiting_subsidiary) this.subsidiaries.push(new Subsidiary(subsidiary))
    else exisiting_subsidiary.patchValues(subsidiary)
  }

  subsidiary_with_id(subsidiary_id: string): Subsidiary {
    return this.subsidiaries.find((subsidiary) => subsidiary.id == subsidiary_id)
  }

  saveSubsidiary(subsidiary: Subsidiary): Observable<ISubsidiary> {
    if (subsidiary.is_new) return this.restApiService.createSubsidiary(subsidiary.as_dict).pipe(tap(() => this.subsidiaries.push(subsidiary)))
    else return this.restApiService.updateSubsidiary(subsidiary.as_dict)
  }

  patchSubsidiary(subsidiary: Subsidiary): Observable<string> {
    const params = {
      subsidiary_id: subsidiary.id
    }
    return this.restApiService.patchSubsidiary(subsidiary.asUpdatedDict, params)
  }

  saveAdminSubsidiary(subsidiary: Subsidiary): Observable<ISubsidiary> {
    if (subsidiary.is_new) return this.adminApiService.createAdminSubsidiary(subsidiary.as_dict).pipe(tap(() => this.subsidiaries.push(subsidiary)))
    else return this.adminApiService.updateAdminSubsidiary(subsidiary.as_dict)
  }
}
