import { FormControl, FormBuilder, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FoodopLibModule } from '../../foodop-lib.module'
import { OrganizationsService } from '../organization/organizations.service'
import { Subsidiary } from '../subsidiary/subsidiary.model'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { UserService } from './user.service'
import { IAccessRole, IUser } from '../../global.models'
import { Organization } from '../organization/organization.model'

export class User {
  id: FormControl
  first_name: FormControl
  last_name: FormControl
  email: FormControl
  title: FormControl
  subsidiary_id: FormControl
  access_role_id: FormControl
  domains: FormControl
  subsidiary_accesses: FormControl
  preferred_language: FormControl

  location_id: FormControl

  saved_user: any
  saving = false

  userService: UserService
  organizationsService: OrganizationsService
  subsidiaryService: SubsidiaryService
  fb: FormBuilder

  constructor(public user?: IUser) {
    this.userService = FoodopLibModule.injector.get(UserService)
    this.organizationsService = FoodopLibModule.injector.get(OrganizationsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)

    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = this.fb.control({ value: user?.id, disabled: false }, [Validators.required])
    this.first_name = this.fb.control({ value: user?.first_name, disabled: false }, [Validators.required])
    this.last_name = this.fb.control({ value: user?.last_name, disabled: false }, [Validators.required])
    this.email = this.fb.control({ value: user?.email, disabled: false }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
    this.title = this.fb.control({ value: user?.title, disabled: false }, [Validators.required])
    this.subsidiary_id = this.fb.control({ value: user?.subsidiary_id, disabled: false })
    this.access_role_id = this.fb.control({ value: user?.access_role_id, disabled: false }, [Validators.required])
    this.domains = this.fb.control({ value: user?.domains, disabled: false }, [Validators.required])
    this.subsidiary_accesses = this.fb.control(user?.subsidiary_accesses)
    this.preferred_language = this.fb.control({ value: user?.preferred_language, disabled: false }, [Validators.required])

    this.location_id = this.fb.control(user?.location_id)

    this.saved_user = JSON.stringify(this.as_dict)
  }

  get changed(): boolean {
    return this.saved_user != JSON.stringify(this.as_dict)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  save(): Observable<User> {
    this.saving = true
    return this.userService.saveProfile(this.as_dict).pipe(
      tap(() => {
        this.saved_user = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  get organization(): Organization {
    return this.organizationsService.organization_with_id(this.subsidiary?.organization_id.value)
  }
  get subsidiary(): Subsidiary {
    return this.subsidiaryService.subsidiary_with_id(this.subsidiary_id.value)
  }

  get access_role(): IAccessRole {
    return this.user?.access_role
  }

  get is_new(): Boolean {
    return this.id == undefined
  }

  get is_user_admin(): boolean {
    return this.access_role.name == 'admin' || this.access_role.name == 'super-admin'
  }

  get is_user_super_admin(): boolean {
    return this.access_role.name == 'super-admin'
  }

  get is_user_beta_tester(): boolean {
    return this.access_role.name == 'admin' || this.access_role.name == 'super-admin' || this.access_role.name == 'beta-tester'
  }

  user_has_access(domain_name: string): boolean {
    return !!this.domains.value.find((domain) => domain.name == domain_name)
  }

  get accessible_subsidiaries(): Subsidiary[] {
    return this.is_user_admin ? this.organization?.activeSubsidiaries : this.subsidiary_accesses.value.map((subsidiaryAccess) => this.subsidiaryService.subsidiary_with_id(subsidiaryAccess.id)).filter((subsidiary) => subsidiary.active.value)
  }

  get as_dict(): IUser {
    return {
      id: this.id.value,
      first_name: this.first_name.value,
      last_name: this.last_name.value,
      email: this.email.value,
      title: this.title.value,
      subsidiary_id: this.subsidiary_id.value,
      access_role_id: this.access_role_id.value,
      domains: this.domains.value,
      subsidiary_accesses: this.subsidiary_accesses.value,
      preferred_language: this.preferred_language.value,
      location_id: this.location_id.value
    }
  }
}
