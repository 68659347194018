import { FormControl } from '@angular/forms'
import { IRecipeLocationPortion } from '../../global.models'
import { RecipePortion } from './recipe-portion.model'
import { Location } from '../location/location.model'
import { round } from '../../utils/number-operators'

export class RecipeLocationPortion {
  public recipePortionId: string
  public index: FormControl = new FormControl()
  public servings: FormControl = new FormControl()
  public locationId: string

  private _savedObject: string

  constructor(recipeLocationPortion?: IRecipeLocationPortion, recipePortion?: RecipePortion, location?: Location) {
    this.recipePortionId = recipeLocationPortion?.portion_id || recipePortion?.id
    this.locationId = recipeLocationPortion?.location_id || location?.id

    this.servings.setValue(round(recipeLocationPortion?.servings != undefined ? recipeLocationPortion?.servings : recipePortion?.activeMenuLocations?.length ? recipePortion?.servings?.value / recipePortion?.activeMenuLocations?.length : 100, 1).toString())
    this.index.setValue(round(recipeLocationPortion?.index != undefined ? recipeLocationPortion?.index : 1, 1).toString())

    this._savedObject = JSON.stringify(this.as_dict)
  }

  public get changed(): boolean {
    return this._savedObject != JSON.stringify(this.as_dict)
  }

  public get as_dict(): IRecipeLocationPortion {
    return {
      portion_id: this.recipePortionId,
      location_id: this.locationId,
      servings: Number(this.servings.value),
      index: Number(this.index.value)
    }
  }
}
