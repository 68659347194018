import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { IRecipe } from '../../global.models'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  recipes = []

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService) {}

  getRecipeByID(id: string, menu_dish_id?: string, menu_id?: string): Observable<IRecipe> {
    const params = {
      fields: 'all',
      language: this.language
    }
    if (menu_dish_id) params['menu_dish_id'] = menu_dish_id
    if (menu_id) params['menu_id'] = menu_id
    return this.restApiService.getRecipe(id, params)
  }

  getRecipesByMenu(menu_id?: string): Observable<IRecipe[]> {
    const params = {
      fields: 'all',
      language: this.language,
      menu_id: menu_id
    }
    return this.restApiService.getRecipes(params).pipe(
      map((dishes: IRecipe[]) => {
        return dishes?.map((dish) => Object.assign(dish['recipes'][0], dish['insights'][0])) // Quick fix to return only one recipe
      })
    )
  }

  upsertRecipe(recipe: IRecipe, replace_with_exact_match, imageFile?: File): Observable<IRecipe> {
    const params = {
      fields: 'all',
      language: this.language,
      replace_with_exact_match: replace_with_exact_match
    }

    let data: IRecipe | FormData = recipe
    if (imageFile) {
      const formData: FormData = new FormData()
      formData.append('file', imageFile, imageFile.name)
      formData.append('json', JSON.stringify(recipe))
      data = formData
    }

    if (recipe.id) return this.restApiService.updateRecipe(data, params)
    else return this.restApiService.createRecipe(data, params)
  }

  public hideRecipe(recipeId): Observable<any> {
    return this.restApiService.hideRecipe(recipeId)
  }

  searchNames(name: string, catalogue_id: string, offset: number): Observable<any[]> {
    if (!name || name.length <= 1) {
      return of([])
    } else {
      const params = {
        query: encodeURIComponent(name),
        limit: 9,
        offset: offset,
        language: this.language,
        fields: ['descriptions,co2,last_date']
      }
      if (catalogue_id) params['catalogue_id'] = catalogue_id
      return this.restApiService.getRecipes(params).pipe(
        map((dishes: IRecipe[]) => {
          return dishes?.map((dish) => Object.assign(dish['recipes'][0], dish['insights'][0])) // Quick fix to return only one recipe
        })
      )
    }
  }

  requestRecipes(query: string, catalogue_id: string, tags_selected: string[], offset: number, sort_seed: number) {
    const params = {
      query: encodeURIComponent(query ? query : ''),
      limit: 9,
      offset: offset,
      language: this.language,
      sort_seed: sort_seed,
      fields: ['descriptions,co2,last_date,dish_catalogues,sorting_properties,favorited,section_template_types,popularity_stars,portions,insights']
    }
    if (tags_selected.length) params['tags'] = [tags_selected.join(',')]
    if (catalogue_id) params['catalogue_id'] = catalogue_id
    return this.restApiService.getRecipes(params).pipe(
      map((dishes: IRecipe[]) => {
        return dishes?.map((dish) => Object.assign(dish['recipes'][0], dish['insights'][0])) // Quick fix to return only one recipe
      })
    )
  }

  getRecipeByName(name: string): Observable<{ recipe_id: string; archived: boolean }> {
    const params = {
      query: encodeURIComponent(name),
      language: this.language,
      fields: ['descriptions,co2,last_date'],
      check_name: true
    }
    return this.restApiService.getRecipes(params) as Observable<{ recipe_id: string; archived: boolean }>
  }

  getIngredientSuggestions(recipe_name: string): Observable<any[]> {
    const params = {
      recipe_name: encodeURIComponent(recipe_name),
      language: this.language
    }
    return this.restApiService.getIngredientSuggestions(params)
  }

  getIngredientInfo(ingredients: RawIngredient[]): Observable<any[]> {
    const params = {
      language: this.language
    }
    return this.restApiService.getIngredientInfo(
      ingredients.map((ingredient) => ingredient.as_dict),
      params
    )
  }
}
