<ng-container *ngIf="!loadingService.loading; else loadingSpinner">
  <ng-container *ngIf="userService.user?.is_user_super_admin; else NoAccess">
    <app-navigation-bar class="l-overflow l-clear"></app-navigation-bar>
    <router-outlet class="l-overflow"></router-outlet>
  </ng-container>
</ng-container>

<ng-template #NoAccess>
  <div class="l-topmargin-large">
    <h1 class="l-width-full l-text-center">FEJL</h1>
    <label class="l-width-full l-text-center">Du har ikke rettighed til at bruge www.admin.foodoptimize.com</label>
    <label class="l-width-full l-text-center">Log venligst ind som administrator</label>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="h-screen flex flex-col justify-center items-center gap-4">
    <mat-spinner [diameter]="50" color="primary"></mat-spinner>
    <h1 class="text-center">Validerer log ind oplysninger</h1>
    <div class="w-1/4">
      <mat-progress-bar mode="determinate" [value]="loadingService.loadValue"></mat-progress-bar>
    </div>
  </div>
</ng-template>
