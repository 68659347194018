import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { httpOptions } from '../global.types'
import { CacheMapService } from './caching/cache-map.service'
import { ISubsidiary } from '../global.models'
import { apiEndpoints } from './api-endpoints'

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  constructor(private http: HttpClient, private cacheMapService: CacheMapService, @Inject('environment') private environment) {}

  createAdminSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.subsidiariesAdminURL.url, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(() => of({}))
    )
  }

  updateAdminSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.subsidiariesAdminURL.url, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(() => of({}))
    )
  }
}
