<mat-form-field class="l-width-full">
  <mat-label>Lokationsnavn</mat-label>
  <input autocomplete="off" matInput [formControl]="location.name" />
</mat-form-field>

<mat-form-field class="l-width-full">
  <mat-label>Adresse</mat-label>
  <input autocomplete="off" matInput [formControl]="location.address" />
</mat-form-field>

<mat-form-field class="l-width-full">
  <mat-label>Postnummer</mat-label>
  <input autocomplete="off" matInput [formControl]="location.postal" />
</mat-form-field>

<mat-form-field class="l-width-full">
  <mat-label>By</mat-label>
  <input autocomplete="off" matInput [formControl]="location.city" />
</mat-form-field>

<mat-form-field class="l-width-full">
  <mat-label>Land</mat-label>
  <input autocomplete="off" matInput [formControl]="location.country" />
</mat-form-field>

<div class="l-width-full">
  <mat-label>Serveres der mad på denne lokation?</mat-label>
  <mat-slide-toggle color="primary" [formControl]="location.servesFood"></mat-slide-toggle>
</div>

<div class="l-width-full">
  <mat-label>Er denne lokation aktiv?</mat-label>
  <mat-slide-toggle color="primary" [formControl]="location.active"></mat-slide-toggle>
</div>

<label class="l-topmargin-large">Vægtgrupper</label>
<scale-groups [location]="location"></scale-groups>

<button type="button" mat-button [disabled]="!location.valid || !location.changed" (click)="saveLocation()" class="l-float-right l-margin-horizontal-medium l-bottommargin-large primary-button">
  <span><mat-spinner *ngIf="location.saving" [diameter]="19" class="o-inline-block"></mat-spinner></span>Gem rettelser
</button>
