import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { INJECTION_DATA, Subsidiary } from 'foodop-lib'

@Component({
  selector: 'subsidiary',
  templateUrl: './subsidiary.component.html',
  styleUrls: ['./subsidiary.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-border-radius l-padding-large'
  }
})
export class SubsidiaryComponent {
  @Input() subsidiary: Subsidiary
  @Output() subsidiary_saved = new EventEmitter<Subsidiary>()

  constructor(@Inject(INJECTION_DATA) public initData: any) {
    if (!this.subsidiary) this.subsidiary = new Subsidiary(initData)
  }

  public saveSubsidiary(): void {
    this.subsidiary.adminSave().subscribe(() => this.subsidiary_saved.emit(this.subsidiary))
  }
}
