import { Component, OnInit } from '@angular/core'
import { AuthService, LoadingService, UserService } from 'foodop-lib'
import moment from 'moment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(public authService: AuthService, public loadingService: LoadingService, public userService: UserService) {
    moment.locale('da', {
      week: {
        dow: 1
      }
    })
  }

  ngOnInit() {
    this.authService.checkAccount()
    this.authService.subscribeToAuthService()
  }
}
