export function onKeypressEventOnNumericField(event, min?: number, max?: number): void {
  const isValueSelected: boolean = event.srcElement.value.length == event.srcElement.selectionEnd - event.srcElement.selectionStart
  const isValueEmpty: boolean = event.srcElement.value.length == 0
  const isValueZeros: boolean = !isValueEmpty && !event.srcElement.value.match(/[1-9,',','.']/)
  const valueContainsComma: boolean = event.srcElement.value.match(/[',','.']/)
  const isKeyPressNumber: boolean = event.key >= 0 && event.key <= 9
  const isKeyPressZero: boolean = event.key == 0
  const isKeyPressComma: boolean = event.key == ',' || event.key == '.'
  const isValueWithinBoundaries: boolean =
    ((min != undefined && parseFloat(isValueSelected ? event.key : event.srcElement.value + event.key) >= min) || min == undefined) && ((max != undefined && parseFloat(isValueSelected ? event.key : event.srcElement.value + event.key) <= max) || max == undefined)
  const isKeyPressValid: boolean = (isValueWithinBoundaries && isKeyPressNumber && (isValueSelected || !isValueZeros)) || (isKeyPressZero && !isValueZeros) || (isKeyPressComma && !isValueSelected && !valueContainsComma)
  if (!isKeyPressValid) event.preventDefault()
  if (isKeyPressValid && event.key == ',') {
    event.srcElement.value += '.'
    event.preventDefault()
  }
}

export function selectInputField(event: any): void {
  event.target.focus()
  setTimeout(function () {
    event.target.select()
  }, 50)
}
