import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { AccessRolesService, IAccessRole, INJECTION_DATA, Organization, OrganizationsService, SubsidiaryService, User, UserService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-border-radius l-padding-large'
  }
})
export class UserComponent {
  @Output() user_saved = new EventEmitter<User>()

  public user: User
  public customerSearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []

  public organizations$: Observable<Organization[]> = this._loadSearchedOrganizations()
  public accessRoles$: Observable<IAccessRole[]> = this._accessRolesService.loadAccessRoles()

  constructor(@Inject(INJECTION_DATA) public initData: any, public userService: UserService, public organizationsService: OrganizationsService, private subsidiaryService: SubsidiaryService, private _accessRolesService: AccessRolesService) {
    this.user = this.userService.user_with_id(initData.user_id) || new User()
  }

  public userSubsidiaryName(user: User): string {
    return this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)?.name?.value || 'Unallocated'
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public compareSubsidiaryAccessesID(optionOne, optionTwo): boolean {
    return optionOne && optionTwo && optionOne.id && optionOne.id && optionOne.id == optionTwo.id
  }

  public onUserSubsidiaryChanges(user: User): void {
    if (!user.subsidiary_id.value) user.subsidiary_accesses.setValue([])
    else if (user.is_user_admin) {
      const subsidiary = this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)
      user.subsidiary_accesses.setValue(
        subsidiary?.organization?.subsidiaries?.map((subsidiary) => {
          return { id: subsidiary.id }
        })
      )
    } else {
      user.subsidiary_accesses.setValue([])
    }
  }

  public saveUser(): void {
    this.user.save().subscribe(() => this.user_saved.emit(this.user))
  }

  private _loadSearchedOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      tap(() => {
        this.loadingOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('subsidiaries,locations', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }
}
