import { Injectable, Inject } from '@angular/core'
import { Location, DOCUMENT } from '@angular/common'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http' // eslint-disable-line import/no-unresolved
import { AuthenticationResult, BrowserConfigurationAuthError, InteractionType, StringUtils } from '@azure/msal-browser'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalService, ProtectedResourceScopes } from '@azure/msal-angular'
import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _document?: Document

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private msalInterceptorConfig: MsalInterceptorConfiguration,
    private authService: AuthService,
    private msalService: MsalService,
    private location: Location,
    @Inject(DOCUMENT) document?: any
  ) {
    this._document = document as Document
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.msalInterceptorConfig.interactionType !== InteractionType.Popup && this.msalInterceptorConfig.interactionType !== InteractionType.Redirect) {
      throw new BrowserConfigurationAuthError('invalid_interaction_type', 'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration')
    }

    const scopes = this.getScopesForEndpoint(req.url, req.method)

    if (!scopes || scopes.length === 0) {
      return next.handle(req)
    }

    const authRequest =
      typeof this.msalInterceptorConfig.authRequest === 'function'
        ? this.msalInterceptorConfig.authRequest(this.msalService, req, {
            account: this.authService.account
          })
        : { ...this.msalInterceptorConfig.authRequest, ...this.authService.account }

    return this.authService.acquireToken(authRequest, scopes, this.authService.account).pipe(
      switchMap((result: AuthenticationResult) => {
        const headers = req.headers.set('Authorization', `Bearer ${result.accessToken}`)

        const requestClone = req.clone({ headers })
        return next.handle(requestClone)
      })
    )
  }

  private getScopesForEndpoint(endpoint: string, httpMethod: string): Array<string> | null {
    const normalizedEndpoint = this.location.normalize(endpoint)
    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys())
    const matchingProtectedResources = this.matchResourcesToEndpoint(protectedResourcesArray, normalizedEndpoint)
    if (matchingProtectedResources.length > 0) {
      return this.matchScopesToEndpoint(this.msalInterceptorConfig.protectedResourceMap, matchingProtectedResources, httpMethod)
    }
    return null
  }

  private matchResourcesToEndpoint(protectedResourcesEndpoints: string[], endpoint: string): Array<string> {
    const matchingResources: Array<string> = []

    protectedResourcesEndpoints.forEach((key) => {
      const normalizedKey = this.location.normalize(key)
      const absoluteKey = this.getAbsoluteUrl(normalizedKey + '/')
      const keyComponents = new URL(absoluteKey)
      const absoluteEndpoint = this.getAbsoluteUrl(endpoint + '/')
      const endpointComponents = new URL(absoluteEndpoint)
      if (this.checkUrlComponents(keyComponents, endpointComponents)) {
        matchingResources.push(key)
      }
    })

    return matchingResources
  }

  private checkUrlComponents(keyComponents: URL, endpointComponents: URL): boolean {
    const urlProperties = ['protocol', 'host', 'pathname', 'search', 'hash']

    for (const property of urlProperties) {
      if (keyComponents[property]) {
        const decodedInput = decodeURIComponent(keyComponents[property])
        if (!StringUtils.matchPattern(decodedInput, endpointComponents[property])) {
          return false
        }
      }
    }

    return true
  }

  private getAbsoluteUrl(url: string): string {
    const link = this._document.createElement('a')
    link.href = url
    return link.href
  }

  private matchScopesToEndpoint(protectedResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null>, endpointArray: string[], httpMethod: string): Array<string> | null {
    const allMatchedScopes = []

    endpointArray.forEach((matchedEndpoint) => {
      const scopesForEndpoint = []
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint)

      if (methodAndScopesArray === null) {
        allMatchedScopes.push(null)
        return
      }

      methodAndScopesArray.forEach((entry) => {
        if (typeof entry === 'string') {
          scopesForEndpoint.push(entry)
        } else {
          const normalizedRequestMethod = httpMethod.toLowerCase()
          const normalizedResourceMethod = entry.httpMethod.toLowerCase()
          if (normalizedResourceMethod === normalizedRequestMethod) {
            if (entry.scopes === null) {
              allMatchedScopes.push(null)
            } else {
              entry.scopes.forEach((scope) => {
                scopesForEndpoint.push(scope)
              })
            }
          }
        }
      })

      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint)
      }
    })

    if (allMatchedScopes.length > 0) {
      return allMatchedScopes[0]
    }

    return null
  }
}
