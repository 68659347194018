import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { IAccessRole } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class AccessRolesService {
  constructor(private _restApiService: RestApiService) {}

  loadAccessRoles(): Observable<IAccessRole[]> {
    const params = {
      fields: 'all',
      active: true
    }
    return this._restApiService.getAllAccessRoles(params).pipe(catchError(() => []))
  }
}
