<button
  type="button"
  class="border-solid border-primary-600 border rounded-[50px] w-fit flex justify-center items-center cursor-pointer"
  [ngClass]="{
    'bg-primary-500 text-primary-50': type === 'primary',
    'text-primary-500 hover:text-white hover:bg-primary-500 transition duration-200 ease-in-out': type === 'secondary',
    'text-base py-2 px-4': size === 'regular',
    'text-xs py-1 px-3': size === 'small',
    'opacity-70 cursor-not-allowed': disabled,
    'cursor-pointer': !disabled
  }"
  [disabled]="disabled"
>
  <mat-icon *ngIf="icon" class="text-base mr-2">{{ icon }}</mat-icon>
  <mat-spinner *ngIf="spinner" class="mr-2" diameter="16" [color]="type === 'primary' ? 'accent' : 'primary'"></mat-spinner>
  {{ text }}
</button>
