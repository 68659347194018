import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { FoodopLibModule, RestApiService, AdminApiService, CacheMapService, AuthService, WebsocketsService } from 'foodop-lib'

import { MsalRedirectComponent } from '@azure/msal-angular'
import { NavigationBarComponent } from './components/views/navigation-bar/navigation-bar.component'
import { AppRoutingModule } from './routing/app-routing.module'
import { FrontpageComponent } from './components/views/frontpage/frontpage.component'
import { CustomersComponent } from './components/views/customers/customers.component'
import { GatewayComponent } from './components/popups/gateways/gateway/gateway.component'
import { EquipmentComponent } from './components/views/equipment/equipment.component'
import { GatewayLogsComponent } from './components/popups/gateways/gateway-logs/gateway-logs.component'
import { ScaleAndDatePickerComponent } from './components/popups/gateways/scale-dish-picker/scale-dish-picker.component'
import { OperationOverviewComponent } from './components/views/operation-overview/operation-overview.component'
import { ScaleSwapPickerComponent } from './components/views/equipment/scales-overview/scale-swap-picker/scale-swap-picker.component'
import { ScalePickerComponent } from './components/views/equipment/scales-overview/scale-swap-picker/scale-picker/scale-picker.component'
import { UsersComponent } from './components/views/customers/users/users.component'
import { OrganizationsComponent } from './components/views/customers/organizations/organizations.component'
import { SubsidiariesComponent } from './components/views/customers/subsidiaries/subsidiaries.component'
import { LocationsComponent } from './components/views/customers/locations/locations.component'
import { ScaleGroupsComponent } from './components/views/customers/scale-groups/scale-groups.component'
import { AnalysesComponent } from './components/views/analyses/analyses.component'
import { DatePickerComponent } from './components/selectors/date-picker/date-picker.component'
import { OrganizationComponent } from './components/views/customers/organizations/organization/organization.component'
import { SubsidiaryComponent } from './components/views/customers/subsidiaries/subsidiary/subsidiary.component'
import { LocationComponent } from './components/views/customers/locations/location/location.component'
import { UserComponent } from './components/views/customers/users/user/user.component'
import { LanguagePickerComponent } from './components/selectors/language-picker/language-picker.component'

import { registerLocaleData } from '@angular/common'
import localeDa from '@angular/common/locales/da'
import localeEn from '@angular/common/locales/en'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
registerLocaleData(localeDa)
//registerLocaleData(localeEn)

import { environment } from '../environments/environment'
import { ChartWidgetComponentV2 } from './components/popups/chart-widget/chart-widget.component'
import { ScatterLineChart } from './components/popups/chart-widget/scatter-line-chart'
import { ScalesOverviewComponent } from './components/views/equipment/scales-overview/scales-overview.component'
import { MatSelectScrollDirective } from './directives/mat-select-scroll.directive'
import { MatAutocompleteScrollDirective } from './directives/mat-autocomplete-scroll.directive'
import { GatewaysOverviewComponent } from './components/views/equipment/gateways-overview/gateways-overview.component'

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    FrontpageComponent,
    CustomersComponent,
    EquipmentComponent,
    GatewayComponent,
    GatewayLogsComponent,
    ScaleAndDatePickerComponent,
    OperationOverviewComponent,
    ScaleSwapPickerComponent,
    ScalePickerComponent,
    UsersComponent,
    OrganizationsComponent,
    SubsidiariesComponent,
    LocationsComponent,
    ScaleGroupsComponent,
    AnalysesComponent,
    DatePickerComponent,
    LanguagePickerComponent,
    OrganizationComponent,
    SubsidiaryComponent,
    LocationComponent,
    UserComponent,
    ChartWidgetComponentV2,
    ScatterLineChart,
    MatAutocompleteScrollDirective,
    MatSelectScrollDirective,
    ScalesOverviewComponent,
    GatewaysOverviewComponent
  ],
  imports: [BrowserModule, AppRoutingModule, FoodopLibModule.forRoot(environment, true)],
  providers: [
    { provide: LOCALE_ID, useValue: window.location.href.replace(window.location.origin, '').slice(1, 3) },
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    RestApiService,
    AdminApiService,
    CacheMapService,
    AuthService,
    WebsocketsService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
