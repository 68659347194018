import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loading = false
  public loadValue = 0

  setLoading(isLoading: boolean) {
    this.loading = isLoading
  }

  setLoadValue(value: number) {
    this.loadValue = value
  }
}
