import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FrontpageComponent } from '../components/views/frontpage/frontpage.component'
import { CustomersComponent } from '../components/views/customers/customers.component'
import { EquipmentComponent } from '../components/views/equipment/equipment.component'
import { AnalysesComponent } from '../components/views/analyses/analyses.component'
import { OperationOverviewComponent } from '../components/views/operation-overview/operation-overview.component'
import { AuthGuard, UserResolver } from 'foodop-lib'

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: FrontpageComponent, canActivate: [AuthGuard], resolve: { userData: UserResolver }, data: { loadUser: true } },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], resolve: { userData: UserResolver }, data: { loadUser: true } },
  { path: 'equipment', component: EquipmentComponent, canActivate: [AuthGuard], resolve: { userData: UserResolver }, data: { loadUser: true } },
  { path: 'operations', component: OperationOverviewComponent, canActivate: [AuthGuard], resolve: { userData: UserResolver }, data: { loadUser: true } },
  { path: 'analyses', component: AnalysesComponent, canActivate: [AuthGuard], resolve: { userData: UserResolver }, data: { loadUser: true } }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
