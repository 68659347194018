<div #navbar class="l-display-flex l-space-between l-flex-centered l-bottommargin-ultra-large">
  <a #logo href="/welcome" class="l-font-logo l-padding-large l-cursor-pointer">FooDOP <label class="font-['Montserrat'] l-font-micro">ADMIN</label></a>

  <ul [hidden]="!showNavList" #navlist class="l-display-flex l-flex-end">
    <li>
      <a href="/welcome">Forside</a>
      <div *ngIf="activeRoute == '/welcome'" class="l-height-1 l-width-95p l-margin-left-auto l-margin-right-auto l-position-relative l-theme-darkgreen l-position-bottom-small"></div>
    </li>

    <li>
      <a href="/customers">Kundeadministration</a>
      <div *ngIf="activeRoute == '/customers'" class="l-height-1 l-width-95p l-margin-left-auto l-margin-right-auto l-position-relative l-theme-darkgreen l-position-bottom-small"></div>
    </li>

    <li>
      <a href="/equipment">Udstyrsadministration</a>
      <div *ngIf="activeRoute == '/equipment'" class="l-height-1 l-width-95p l-margin-left-auto l-margin-right-auto l-position-relative l-theme-darkgreen l-position-bottom-small"></div>
    </li>

    <li>
      <a href="/operations">Driftoverblik</a>
      <div *ngIf="activeRoute == '/operations'" class="l-height-1 l-width-95p l-margin-left-auto l-margin-right-auto l-position-relative l-theme-darkgreen l-position-bottom-small"></div>
    </li>

    <li>
      <a href="/analyses">Analyse</a>
      <div *ngIf="activeRoute == '/analyses'" class="l-height-1 l-width-95p l-margin-left-auto l-margin-right-auto l-position-relative l-theme-darkgreen l-position-bottom-small"></div>
    </li>

    <li>
      <a (click)="onClickLogout()" class="l-cursor-pointer"><i class="fa fa-utensils"></i>Log ud</a>
    </li>
  </ul>
</div>
